<template>
  <div class="home">
    <Banner />
    <Services />
    <Process />
    <TGLGroup />
    <TechTask />
    <About />
    <OurProjects />
    <BIMCADHighlight />
    <Poem />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';

const Banner = () => import('../components/Banner/BannerNew');
const Services = () => import('../components/Renew/Home/Services.vue');
const OurProjects = () => import('../components/Renew/Home/OurProjects.vue');
const Process = () => import('../components/Renew/Home/Process.vue');
const TGLGroup = () => import('../components/Renew/Home/TGLGroup.vue');
const TechTask = () => import('../components/Renew/Home/TechTask.vue');
const Poem = () => import('../components/Renew/Recruitment/Poem.vue');
const BIMCADHighlight = () =>
  import('../components/Renew/Home/BIMCADHighlight.vue');
const Contact = () => import('../components/Renew/Home/Contact.vue');
const About = () => import('../components/Renew/Home/About.vue');
const Footer = () => import('../views/FooterNew.vue');

export default {
  name: 'Home',

  data() {
    return {
      service: [
        {
          icon: '/images/technical-icon/web-development.png',
        },
        {
          icon: '/images/technical-icon/user-interface.png',
        },
        {
          icon: '/images/technical-icon/web-analytics.png',
        },
        {
          icon: '/images/technical-icon/cad.png',
        },
        {
          icon: '/images/technical-icon/web-analytics.png',
        },
        {
          icon: '/images/technical-icon/cad.png',
        },
      ],
      imgClient: [
        '/images/home/mari.png',
        '/images/home/cad-logo.png',
        '/images/home/hi.png',
        '/images/home/team.png',
      ],
      about_us: this.$t('about_us'),
      header: this.$t('header'),
      contact: this.$t('contact'),
      home: this.$t('home'),
      project: this.$t('project'),
      content_contact: this.$t('footer').contents,
      process: this.$t('home').process,
      seo: this.$t('seo').home,
    };
  },
  metaInfo() {
    return {
      title: this.seo.title,
      meta: [
        {
          name: 'description',
          content: this.seo.description,
        },
        {
          property: 'og:title',
          content: this.seo.title,
        },
        {
          property: 'og:description',
          content: this.seo.description,
        },
        {
          property: 'og:url',
          content: 'https://tgl-sol.com/',
        },
        {
          name: 'keywords',
          content: this.seo.keywords,
        },
        {
          name: 'robots',
          content: 'index, follow',
        },
      ],
    };
  },

  components: {
    OurProjects,
    Banner,
    Services,
    Process,
    TGLGroup,
    TechTask,
    Contact,
    About,
    Poem,
    Footer,
    BIMCADHighlight,
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  methods: {},
  watch: {
    lang() {
      this.about_us = this.$t('about_us');
      this.header = this.$t('header');
      this.contact = this.$t('contact');
      this.home = this.$t('home');
      this.project = this.$t('project');
      this.content_contact = this.$t('footer').contents;
      this.process = this.$t('home').process;
      this.seo = this.$t('seo').home;
    },
  },
};
</script>
